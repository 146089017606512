import React, { useEffect, useState } from "react";
import { SelectMatchesButtons } from "./selectMatchesButtons";
import { MatchesList } from "./playerMatchesList";
import { SelectСoverageButtons } from "./selectСoverageButtons";
import { TMatch, TStatistics, TTourMatchPlayerData } from "app/api/types";
import style from './style.module.scss'
import { FormattedMessage, useIntl } from "react-intl";
import { apiProvider } from "app/api/api-provider";

type TArgMatchesWidget = {
    setNowMatchData: React.Dispatch<React.SetStateAction<any>>,
    setStat: (statistics: TStatistics) => void,
    matchData: TMatch
}

export const MatchesWidget = ({
    setNowMatchData,
    setStat,
    matchData
}: TArgMatchesWidget): JSX.Element => {
    const [selectMatches, setSelectMatches] = useState<number>(0)
    const [selectСoverage, setSelectСoverage] = useState<number>(0)
    const [player1Tournaments, setPlayer1Tournaments] = useState<TTourMatchPlayerData[]>([])
    const [player2Tournaments, setPlayer2Tournaments] = useState<TTourMatchPlayerData[]>([])
    const [player1MatchesFiltered, setPlayer1MatchesFiltered] = useState<TMatch[]>([])
    const [player2MatchesFiltered, setPlayer2MatchesFiltered] = useState<TMatch[]>([])
    const intl = useIntl()

    useEffect(() => {
        getMatchesPlayer()
    },[])

    useEffect(() => {
        player1Tournaments.forEach((tour: TTourMatchPlayerData) => {
            const nowMatch = tour.matches.find((match: TMatch) => match._id === matchData._id)
            if(nowMatch !== undefined) {
                setNowMatchData({
                    tourId: tour.id,
                    tourName: tour.name,
                    country: tour.country,
                    match: nowMatch
                })
            }   
        })
    },[player1Tournaments])

    useEffect(() => {
        setPlayer1MatchesFiltered(filterMatches(player1Tournaments).reverse())
        setPlayer2MatchesFiltered(filterMatches(player2Tournaments).reverse())
    },[player1Tournaments, player2Tournaments, selectСoverage, selectMatches])

    const getTourMatchesPlayer = async(playerId: string) => {
        const res = await apiProvider.getTourMatchPlayerData(playerId)
        if(!res.tournaments) {
            return
        }
        return res.tournaments
    }

    const getMatchesPlayer = async () => {
        const responsePlayer1 = await getTourMatchesPlayer(matchData.team1Names[0]._id)
        const responsePlayer2 = await getTourMatchesPlayer(matchData.team2Names[0]._id)
        if(responsePlayer1){
            setPlayer1Tournaments(responsePlayer1)
        }
        if(responsePlayer2){
            setPlayer2Tournaments(responsePlayer2)
        }
    }

    const filterMatches = (matchesList: TTourMatchPlayerData[]) => {
        if(matchesList.length === 0) {
            return []
        }
        
        const filteredMatches = matchesList.map((tour: TTourMatchPlayerData) => {
            if(
                (selectСoverage === 1 && tour.coverage !== 'ground') ||
                (selectСoverage === 2 && tour.coverage !== 'grass') ||
                (selectСoverage === 3 && tour.coverage !== 'hard')
            ) {
                return []
            }
            const matches: TMatch[] = tour.matches.filter((match:TMatch) => {

                //В H2H убираем очные встречи
                if(selectMatches === 0 && (
                    (match.team1Names[0].name === matchData.team1Names[0].name && match.team2Names[0].name === matchData.team2Names[0].name) ||
                    (match.team1Names[0].name === matchData.team2Names[0].name && match.team2Names[0].name === matchData.team1Names[0].name)
                )) {
                    return
                }

                //В очных встречах убираем последние игры 
                if(selectMatches === 1 && !(
                    (match.team1Names[0].name === matchData.team1Names[0].name && match.team2Names[0].name === matchData.team2Names[0].name) ||
                    (match.team1Names[0].name === matchData.team2Names[0].name && match.team2Names[0].name === matchData.team1Names[0].name)
                )) {
                    return
                }
                return match
            })

            if(matches.length !== 0){
                return matches.map((match: TMatch) => {
                    return {
                        ...match,
                        tourId: tour.id,
                        tourName: tour.name,
                        country: tour.country
                    } 
                }).reverse()
            }
            return []
        })
        if(filteredMatches.flat(1).length === 0){
            return []
        }
        
        return filteredMatches.flat(1)
    }

    return (
        <>
            <SelectMatchesButtons selectMatches={selectMatches} setSelectMatches={setSelectMatches}/>
            <SelectСoverageButtons selectСoverage={selectСoverage} setSelectСoverage={setSelectСoverage}/>

            {selectMatches === 0 && <div className={style.Matches}>
                <div className={style.header}>{`${intl.formatMessage({id: "latestGames"})}: ${matchData.team1Names[0].name}`}</div>
                {
                    player1MatchesFiltered.length === 0 && 
                        <div className={style.NoMatch}>
                            <FormattedMessage id={'noMatches'}/>
                        </div>
                }
                <MatchesList matchesList={player1MatchesFiltered} onClick={(match: any) => {
                    setStat(match.statistics)
                    setNowMatchData({
                        tourId: match.tourId,
                        tourName: match.tourName,
                        country: match.country,
                        match: match
                    })
                }}/>
                
                <div className={style.header}>{`${intl.formatMessage({id: "latestGames"})}: ${matchData.team2Names[0].name}`}</div>
                {
                    player2MatchesFiltered.length === 0 && 
                        <div className={style.NoMatch}>
                            <FormattedMessage id={'noMatches'}/>
                        </div>
                }
                <MatchesList matchesList={player2MatchesFiltered} onClick={(match: any) => {
                    setStat(match.statistics)
                    setNowMatchData({
                        tourId: match.tourId,
                        tourName: match.tourName,
                        country: match.country,
                        match: match
                    })
                }}/>
            </div>}

            {selectMatches === 1 && <div className={style.Matches}>
                <div className={style.header}>{intl.formatMessage({id: "face-to-faceMeetings"})}</div>
                {
                    player1MatchesFiltered.length === 0 && 
                        <div className={style.NoMatch}>
                            <FormattedMessage id={'noMatches'}/>
                        </div>
                }
                <MatchesList matchesList={player1MatchesFiltered} onClick={(match: any) => {
                    setStat(match.statistics)
                    setNowMatchData({
                        tourId: match.tourId,
                        tourName: match.tourName,
                        country: match.country,
                        match: match
                    })
                }}/>
            </div>}
            
        </>
    )
}  