import React, { useEffect, useState } from "react"
import style from './style.module.scss'
import { CountryBar } from "features/tournament-table/tournament-line/ui/country-bar/CountryBar"
import { apiProvider } from "app/api/api-provider"
import avatar from 'images/avatar.svg'
import { useNavigate } from "react-router-dom"
import { TMatch, TPlayer, TScore } from "app/api/types"
import { FormattedMessage } from "react-intl"
import { TNowMatchData } from ".."

const statusMatch: any = {
    2: 'matchProgress',
    4: 'completed',
}

type TArgPlayersWidget = {
    matchData: TNowMatchData
}

export const PlayersWidget = ({
    matchData
}: TArgPlayersWidget): JSX.Element => {
    const {match, tourId, tourName, country} = matchData
    const navigate = useNavigate()
    const statistics = match.statistics
    const [player1, setPlayer1] = useState<TPlayer>()
    const [player2, setPlayer2] = useState<TPlayer>()

    useEffect(() => {
        getPlayers()
    },[match])

    async function getPlayers() {
        const resposePlayer1 = await apiProvider.getPlayerData({id: statistics.player1[0]})
        const resposePlayer2 = await apiProvider.getPlayerData({id: statistics.player2[0]})
        setPlayer1(resposePlayer1.player)
        setPlayer2(resposePlayer2.player)
    }
    
    const dateMatch = () => {
        const date = new Date(parseInt(match.datetime))
        return date.toLocaleDateString() + " " + date.toLocaleTimeString()
    }

    const scoreMatch = () => {
        let countPlayer1 = 0 
        let countPlayer2 = 0
        match.team1Score.forEach((set: TScore, index: number) => {
            if(set.score > match.team2Score[index].score) {
                countPlayer1 += 1
            }
            if(set.score < match.team2Score[index].score) {
                countPlayer2 += 1
            }
        });
        return `${countPlayer1}-${countPlayer2}`
    }

    /* CountryBar провести сюды инфу о турнире */


    return (
        <>
            <div className={style.PlayersWidget}>
                <div className={style.Tour}>
                    {country && tourName && <div onClick={() => navigate(`/tournaments/${tourId}`)}>
                        <CountryBar countryName={tourName} flag={country}/>
                    </div>}
                </div>
                <div className={style.Players}>
                    <div className={style.Player}>
                        {player1 && <div onClick={() => navigate(`/players/${player1.id}`)}>
                            <img alt={"no"} src={player1.avatar || avatar}/>
                            <CountryBar countryName={''} flag={player1.country} />
                            <div className={style.Name}>{player1.name}</div>
                        </div>}
                    </div>
                    <div className={style.Score}>
                        <div>{dateMatch()}</div>
                        <div>{scoreMatch()}</div>
                        <div>
                            <FormattedMessage id={statusMatch[match.status]}/>
                        </div>
                        <div className={style.setsScore}>
                            {match?.team1Score?.length !== 0 && match.team1Score.map((scoreSet: TScore, index: number) => <>
                            <div>
                                <div>{scoreSet.score}</div>
                                <div>{match.team2Score[index].score}</div>
                            </div>
                           
                            </>)}
                        </div>
                    </div>
                    <div className={style.Player}>
                        {player2 && <div onClick={() => navigate(`/players/${player2.id}`)}>
                            <img alt={"no"} src={player2.avatar || avatar}/>
                            <CountryBar countryName={''} flag={player2.country} />
                            <div className={style.Name}>{player2.name}</div>
                        </div>}
                    </div>
                </div>
            </div>
        </>
    )
}